import React from 'react'
import { Heading, Center, Stack, Button } from '@chakra-ui/react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import {MotionBox} from '../components/atoms/MotionBox'
import { randomNumber } from '../utils/randomNumber'

const NotFoundPage = () => (
    <>
        <SEO title="404: Nothing to see here 👀" />
        <Center minHeight="full">
            <Stack align="center" spacing={[6, null, 10]}>
                <Heading as="h1" fontSize="clamp(6em, 20vw, 30em)">
                    {'404'.split('').map((char, i) => (
                        <MotionBox
                            key={i}
                            drag
                            layout="position"
                            as="span"
                            display="inline-block"
                            cursor="grab"
                            initial={{ rotate: 0 }}
                            animate={{ rotate: i % 2 === 0 ? -360 : 360 }}
                            transition={{
                                repeat: Infinity,
                                ease: 'linear',
                                duration: randomNumber(4, 10),
                            }}
                        >
                            {char}
                        </MotionBox>
                    ))}
                </Heading>
                <Stack spacing={4}>
                    <Heading as="h2" fontSize="2xl" textAlign="center">
                        ...Nothing to to see here 👀
                    </Heading>
                    <Button as={Link} to="/" colorScheme="blue" size="lg">
                        Take me back home 🏠
                    </Button>
                </Stack>
            </Stack>
        </Center>
    </>
)

export default NotFoundPage
